var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"procedures"},[_c('z-input',{staticClass:"procedures__search",attrs:{"name":"search","theme":"search","placeholder":"Введите запрос"},on:{"input":_vm.onInput}}),_vm._v(" "),(_vm.loading)?_c('z-preloader'):(_vm.proceduresNav.length)?[_c('div',{staticClass:"procedures__list"},_vm._l((_vm.proceduresNav),function(ref,index){
var name = ref.name;
var url = ref.url;
var procedureType = ref.procedureType;
var publishDate = ref.publishDate;
var acceptanceEndDate = ref.acceptanceEndDate;
var status = ref.status;
return _c('procedures-item',{key:index,attrs:{"name":name,"url":url,"procedureType":procedureType,"publishDate":publishDate,"acceptanceEndDate":acceptanceEndDate,"status":status}})}),1),_vm._v(" "),_c('z-pagination',{staticClass:"procedures__pagination",attrs:{"data":_vm.navData,"showPrev":"","showNext":"","showNav":"","theme":"corps"},on:{"change-page":_vm.pageChange}})]:_c('z-not-found',{attrs:{"title":"Процедуры не найдены"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }