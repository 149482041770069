<template>
    <div class="news-events">
        <div class="row">
            <div class="col-default-6 col-v-tablet-12">
                <z-card
                    :link="data[0].link"
                    full-height
                >
                    <template v-slot:content>
                        <date-block
                            class="u-bottom-margin--s"
                            :date="data[0].date"
                            :lang="data[0].lang"
                        ></date-block>
                        <span class="news-events__last-news-caption" v-html="data[0].name"></span>
                    </template>
                </z-card>
            </div>
            <div class="col-default-6 col-v-tablet-12">
                <div class="row">
                    <div class="col-default-6 col-mobile-12">
                        <z-card
                            :link="data[1].link"
                            full-height
                        >
                            <template v-slot:content>
                                <date-block
                                    class="u-bottom-margin--s"
                                    :date="data[1].date"
                                    :lang="data[1].lang"
                                ></date-block>
                                <span class="u-light" v-html="data[1].name"></span>
                            </template>
                        </z-card>
                    </div>
                    <div class="col-default-6 col-mobile-12">
                        <z-card
                            :link="data[2].link"
                            full-height
                        >
                            <template v-slot:content>
                                <date-block
                                    class="u-bottom-margin--s"
                                    :date="data[2].date"
                                    :lang="data[2].lang"
                                ></date-block>
                                <span v-html="data[2].name"></span>
                            </template>
                        </z-card>
                    </div>
                    <div class="col-default-12">
                        <slot name="last-event" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'news-events',
    props: {
        data: {
            type: Array,
            default: () => {
                return null
            }
        }
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss">
    .news-events__last-news-caption {
        font-family: 'Future';
        font-size: 30px;
        line-height: 1.33;
        max-width: 400px;
        display: block;
    }

    @include breakpoint(mobile) {
        .news-events__last-news-caption {
            font-size: 22px;
        }
    }

    .news-events span.u-bold,  
    .news-events span.z-caption__text {
        font-family: 'Future';
    }

    .news-events span.last-event__type {
    font-family: 'Future';
    font-size: 20px;
    }
</style>
