<template>
    <div class="procedures-item">
        <span>
            <a :href="url" class="procedures-item__name" target="_blank">{{ name }}</a>
        </span>

        <div class="procedures-item__options-list">
            <div v-for="({ name, value }, index) in options" :key="index" class="procedures-item__option">
                <span class="procedures-item__option-name">{{ name }}</span>
                <span class="procedures-item__option-value">{{ value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'procedures-item',
    props: {
        name: String,
        url: String,
        procedureType: String,
        publishDate: String,
        acceptanceEndDate: String,
        status: String
    },
    data () {
        return {
        }
    },
    computed: {
        formatedProcedureType () {
            switch (this.procedureType) {
            case 'Buy':
                return 'Предложение'
            case 'Sell':
                return 'Продажа'
            case 'Registry':
                return 'Завершено'
            default:
                return '-'
            }
        },
        formatedPublishDate () {
            if (this.publishDate) {
                return moment(this.publishDate).format('DD.MM.YYYY')
            }

            return '-'
        },
        formatedAcceptanceEndDate () {
            if (this.acceptanceEndDate) {
                return 'до ' + moment(this.acceptanceEndDate).format('DD.MM.YYYY')
            }

            return '-'
        },
        formatedStatus () {
            switch (this.status) {
            case 'Ended':
                return 'Завершен'
            case 'Proposal':
                return 'Предложение'
            case 'Summarizing':
                return 'Подведение итогов'
            default:
                return '-'
            }
        },
        options () {
            return [
                {
                    name: 'Тип процедуры:',
                    value: this.formatedProcedureType
                },
                {
                    name: 'Опубликован:',
                    value: this.formatedPublishDate
                },
                {
                    name: 'Прием предложений:',
                    value: this.formatedAcceptanceEndDate
                },
                {
                    name: 'Статус:',
                    value: this.formatedStatus
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.procedures-item {
    $parent: &;

    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--color-gold);
    background: var(--color-crisp-white);

    &__name {
        color: var(--color-blue-darkest);
        font-family: "Future";
        font-size: 24px;
        font-weight: 300;
        line-height: 120%;

        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 95%;

        &::before {
            content: '';
            display: block;
            float: left;
            margin-top: 6.5px;
            margin-right: 16px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: var(--color-gold);
        }
    }

    &__options-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 16px;

        #{$parent}__option {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &-name {
                color: var(--color-blue-darkest);
                font-size: 15px;
                font-weight: 400;
                line-height: 120%;
            }

            &-value {
                color: var(--color-kaviar-black);
                font-family: "Future";
                font-size: 20px;
                font-weight: 300;
                line-height: 126%;
            }
        }
    }
}
</style>
