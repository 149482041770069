<template>
    <div class="news-detail">
        <z-button
            class="news-detail__back-link"
            kind="text"
            size="xs"
            tag="a"
            :href="backLink"
        >
            {{ text.backLink }}
            <template v-slot:left>
                <z-icon name="arrow"></z-icon>
            </template>
        </z-button>
        <date-block
            class="news-detail__date"
            :date="date"
            :lang="lang"
        ></date-block>
        <div class="news-detail__content">
            <z-caption tag="h2">
                <span v-html="title"></span>
            </z-caption>
            <div class="html-content">
                <slot></slot>
            </div>
            <page-changer
                :back-link="backLink"
                :next="next"
                :prev="prev"
                class="u-top-margin--l"
            ></page-changer>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { localize } from '@/utils/i18n'

export default {
    name: 'detail-page',
    props: {
        date: [String, Number],
        title: String,
        backLink: {
            type: String,
            default: '/'
        },
        next: String,
        prev: String,
        anotherLangLink: String,
        lang: {
            type: String,
            default: 'ru'
        }
    },
    data () {
        return {
            text: {
                backLink: localize({
                    'ru': 'Назад к списку',
                    'en': 'Back to the list'
                })
            }
        }
    },
    methods: {
        ...mapActions([
            'SetSiteSelectorLink'
        ])
    },
    created () {
        if (this.anotherLangLink.length > 0) {
            this.SetSiteSelectorLink(this.anotherLangLink)
        }
    }
}
</script>

<style lang="scss">
.news-detail {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-top: 64px;

    @include breakpoint (v-tablet) {
        display: block;
    }

    strong {
        font-family: Fact;
        font-weight: 300;
    }

    &__date {
        padding-right: $token-spacers-m;
        border-right: 1px solid $token-colors-gold;
        flex-shrink: 0;
        margin-right: $token-spacers-m;
        margin-bottom: $token-spacers-s;

        @include breakpoint (v-tablet) {
            border-right: none;
            border-bottom: 1px solid $token-colors-gold;
            display: inline-flex;
            padding-right: 0;
            padding-bottom: $token-spacers-s;
        }
    }

    &__back-link {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__content {
        .z-caption {
            font-family: 'Future';
        }
        p {
            font-family: 'Fact';
        }
    }
}
</style>
