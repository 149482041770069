<template>
    <div class="z-not-found">
        <span class="z-not-found__title" v-html="title"></span>
        <p class="z-not-found__text" v-html="text"></p>
    </div>
</template>

<script>
export default {
    name: 'z-not-found',
    props: {
        title: String,
        text: String
    }
}
</script>

<style lang="scss">
.z-not-found {
    position: relative;
    text-align: center;

    &__title {
        display: block;
        margin-bottom: 0.75rem;
        color: #000;
        font-weight: 300;
        line-height: 1;
        font-size: 1.5rem;
    }

    &__text {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
</style>
