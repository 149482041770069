<template>
    <div class="procedures">
        <z-input
            class="procedures__search"
            name="search"
            theme="search"
            placeholder="Введите запрос"
            @input="onInput"
        ></z-input>

        <z-preloader v-if="loading" />

        <template v-else-if="proceduresNav.length">
            <div class="procedures__list">
                <procedures-item v-for="({ name, url, procedureType, publishDate, acceptanceEndDate, status }, index) in proceduresNav"
                    :key="index"
                    :name="name"
                    :url="url"
                    :procedureType="procedureType"
                    :publishDate="publishDate"
                    :acceptanceEndDate="acceptanceEndDate"
                    :status="status"
                />
            </div>

            <z-pagination
                :data="navData"
                showPrev
                showNext
                showNav
                theme="corps"
                class="procedures__pagination"
                @change-page="pageChange"
            />
        </template>

        <z-not-found title="Процедуры не найдены" v-else />
    </div>
</template>

<script>
import ProceduresItem from './ProceduresItem.vue'

export default {
    name: 'procedures',
    components: { ProceduresItem },
    data () {
        return {
            searchValue: '',
            loading: false,
            nav: {
                currentPage: 1
            },
            proceduresListCount: 5,
            proceduresRaw: []
        }
    },
    methods: {
        pageChange (data) {
            this.nav.currentPage = data.page
        },
        onInput (data) {
            this.searchValue = data.value
            this.nav.currentPage = 1
        },
        getProcedures () {
            this.loading = true

            fetch('https://bidzaar.com/api/publicprofile/profile/9ee68107-2c40-4760-a3b8-597463430c08/tenders?all_from=2024-02-21')
                .then(data => data.json())
                .then(data => {
                    this.proceduresRaw = data
                })
                .catch(err => {
                    console.error('Не удалось получить процедуры', err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getProceduresOrder (procedureName) {
            const order = {
                Proposal: 3,
                Summarizing: 2,
                Ended: 1
            }

            return order[procedureName] || 0
        }
    },
    computed: {
        procedures () {
            return this.proceduresRaw || []
        },
        proceduresFiltered () {
            return this.searchValue ? this.procedures.filter(el => {
                return el.name?.toLowerCase()?.includes(this.searchValue.toLowerCase())
            }) : this.procedures
        },
        proceduresSorted () {
            return [...this.proceduresFiltered].sort((a, b) => this.getProceduresOrder(b.status) - this.getProceduresOrder(a.status))
        },
        proceduresNav () {
            const firstIndex = (this.nav.currentPage - 1) * this.proceduresListCount

            return this.proceduresSorted.slice(firstIndex, firstIndex + this.proceduresListCount)
        },
        navData () {
            return {
                currentPage: this.nav.currentPage,
                totalPages: Math.ceil(this.proceduresSorted.length / this.proceduresListCount),
                pageRange: 1
            }
        }
    },
    created () {
        this.getProcedures()
    }
}
</script>

<style lang="scss">
.procedures {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: var(--spacer-m);

    &__search {
        margin-bottom: 0 !important;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__pagination {
        margin-top: var(--spacer-m);
        align-self: center;
    }
}
</style>
