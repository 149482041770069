<template lang="html">
    <div class="ip-contacts">
        <div class="container ip-contacts__container">
            <div class="row u-collapse--top">
                <div class="col-default-6 col-v-tablet-12 u-collapse--top">
                    <div class="ip-contacts__info">
                        <z-caption
                            class="ip-contacts__title"
                            tag="h2"
                            border-decor
                            weight="300"
                            family="fact"
                            color="black"
                            uppercase
                            v-html="text.maintitle"
                        >
                        </z-caption>
                        <p class="small-text u-uppercase u-bottom-margin--s" v-html="text.title.address"></p>
                        <z-caption
                            class="u-bottom-margin--m-important crisp-white-text"
                            weight="300"
                            tag="h2"
                            color="black"
                            v-html="text.address"
                        >
                        </z-caption>
                        <p class="small-text u-uppercase u-bottom-margin--s" v-html="text.title.telephone"></p>
                        <z-link href="tel:+74955102695" :underline="false">
                            <z-caption
                                class="u-bottom-margin--m-important crisp-white-text"
                                weight="300"
                                tag="h2"
                                color="black"
                            >
                                +7 (495) 510-26-95
                            </z-caption>
                        </z-link>
                        <p class="small-text u-uppercase u-bottom-margin--s">Email</p>
                        <z-link href="mailto:ir@novabev.com" :underline="false">
                            <z-caption
                                class="u-collapse--all crisp-white-text"
                                weight="300"
                                tag="h2"
                                color="black"
                            >
                                ir@novabev.com
                            </z-caption>
                        </z-link>
                        <div class="ip-contacts__buttons">
                            <z-button
                                class="ip-contacts__btn js-analytics u-bottom-margin--s u-right-margin--s"
                                tag="a"
                                :href="getLink('/investors/ir-contacts/')"
                                :data-analytics-label="`ClickContactIrInvestors${capitalizeLang}`"
                                data-analytics-action="click"
                                data-analytics-category="content"
                            >
                                <span v-html="text.link"></span>
                            </z-button>
                            <z-button
                                tag="a"
                                :href="getLink('/investors/ir-contacts/#subscribe')"
                                class="ip-contacts__btn"
                                v-html="text.linkModal"
                            ></z-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-default-6 col-v-tablet-12 u-collapse--top">
                    <div class="ip-contacts__card-wrapper">
                        <div class="ip-contacts__img u-bottom-margin--m">
                            <img src="/images/investors/contacts-person.png" alt="">
                        </div>
                        <p class="small-text u-uppercase u-bottom-margin--s">General telephone </p>
                        <z-caption
                            class="u-bottom-margin--s-important crisp-white-text"
                            tag="h2"
                            color="black"
                            >
                            <span>Sergey Kuptsov</span>
                        </z-caption>
                        <p class="u-uppercase u-bottom-margin--s">Head of Corporate Finance</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'contacts',
    data () {
        return {
            text: {
                maintitle: localize({
                    ru: 'Контакты для Инвесторов',
                    en: 'IR contacts'
                }),
                title: {
                    address: localize({
                        ru: 'Адрес',
                        en: 'Address'
                    }),
                    telephone: localize({
                        ru: 'Телефон',
                        en: 'General telephone'
                    })
                },
                address: localize({
                    ru: 'Якиманская наб., д. 4, стр 1, 119180, Москва, Россия',
                    en: '4, Yakimanskaya emb., Bldg. 1, Moscow, 119180, Russia'
                }),
                link: localize({
                    ru: 'Контакты для инвесторов',
                    en: 'Contact IR'
                }),
                linkModal: localize({
                    ru: 'Подписаться на&nbsp;Email рассылку',
                    en: 'Subscribe to&nbsp;Email alerts'
                })
            }
        }
    },
    computed: {
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.ip-contacts {
    background-image: url(/images/investors/contacts-banner.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    color: $token-colors-crisp-white;
    position: relative;
    font-family: 'Fact';

    &__container {
        @include breakpoint (tablet) {
            padding: 0;
        }
    }

    &__info {
        background-color: $token-colors-blue-darkest;
        @include padding-level(top, L);
        @include padding-level(bottom, L);
        @include padding-level(left, M);
        @include padding-level(right, M);
        position: relative;
        overflow: hidden;
        z-index: 2;

        &:after {
            content: '';
            display: block;
            width: 240px;
            height: 240px;
            border: 2px solid $token-colors-gold;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(50%, 50%);

            @include breakpoint (v-tablet) {
                width: 90px;
                height: 90px;
                left: 0;
                right: auto;
                transform: translate(-50%, 50%);
            }
        }

        .small-text {
            font-family: 'Fact';
        }

        .z-caption {
            font-family: 'Future';
        }

        .crisp-white-text {
            font-size: 24px;
        }
    }

    &__title {
        color: inherit !important;
        @include margin-level(bottom, XL);
    }

    &__btn {
        font-size: 18px !important;
    }

    &__img {
        border-radius: 50%;
        overflow: hidden;
        max-width: 230px;

        @include breakpoint (mobile) {
            margin-left: auto;
            margin-right: auto;
            max-width: 160px;
        }

        img {
            max-width: 100%;
        }
    }

    &__card-wrapper {
        padding-top: 180px;
        padding-left: 120px;
        @include padding-level(bottom, L);
        @include padding-level(right, M);
        position: relative;
        z-index: 2;
        height: 100%;

        @include breakpoint (v-tablet) {
            padding: $token-spacers-m $token-spacers-xl;
        }

        @include breakpoint (mobile) {
            padding: $token-spacers-xs;
            text-align: center;
        }

        &:after {
            content: '';
            display: block;
            width: calc(50vw + 10px);
            height: 100%;
            position: absolute;
            top: 0;
            left: -20px;
            background: linear-gradient(0deg, rgba(17, 65, 103, 0.2), rgba(17, 65, 103, 0.2));
            backdrop-filter: blur(12px);
            z-index: -1;

            @include breakpoint (v-tablet) {
                width: 100%;
                left: 0;
            }
        }

        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 390px;
            background-image: url(/images/investors/contacts-lines.svg);
            background-position: 0% 50%;
            background-size: auto 100%;
            left: 100%;
            top: 0;
            position: absolute;

            @include breakpoint (tablet) {
                left: calc(100% - 90px);
            }

            @include breakpoint (v-tablet) {
                left: calc(100% - 140px);
            }

            @include breakpoint (mobile) {
                display: none;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        @include margin-level(top, XL);
        margin-bottom: 120px;

        @include breakpoint (tablet) {
            @include margin-level(bottom, XL);
        }

        @include breakpoint (mobile) {
            flex-direction: column;
        }
    }
}
</style>
