<template>
    <label
        :for="name"
        class="z-input"
        :class="{
            'is-error': error,
            'is-filled': val,
            'is-focused': focus,
            'is-disabled': disabled,
            'z-input--search': theme === 'search',
            'z-input--search-right': theme === 'search-right',
            [`z-input--theme-${colorTheme}`]: colorTheme
        }"
    >
        <z-icon
            v-if="theme === 'search'"
            name="search"
            width="24"
            height="24"
        />
        <input
            :id="name"
            :name="name"
            :type="type"
            :value="val"
            :disabled="disabled"
            @blur="focus = false"
            @focus="focus = true"
            @change="change($event.target.value)"
            @input="input($event.target.value)"
            @keypress.enter="enter($event.target.value)"

            autocomplete="off"
        />
        <z-icon
            v-if="theme === 'search-right'"
            name="search-small"
            width="16"
            height="16"
        />
        <span
            v-if="placeholder && !focus && !val"
            v-html="placeholder"
            class="z-input__placeholder"
        ></span>
        <span
            v-if="required"
            v-html="'*'"
            class="z-input__required-sign"
        ></span>
        <span
            class="z-input__error"
            v-if="required && error"
        >
            <span v-if="!val">{{ text.errors.required }}</span>
            <span v-else-if="type === 'email'">{{ text.errors.invalid.email }}</span>
        </span>
    </label>
</template>

<script>
import { phoneMask } from '@/utils/directives'

export default {
    name: 'z-input',
    props: {
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'tel', 'email', 'password'].includes(prop)
        },
        theme: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'search', 'search-right'].includes(prop)
        },
        disabled: Boolean,
        required: Boolean,
        placeholder: String,
        value: [Number, String],
        colorTheme: {
            type: String,
            default: 'main',
            validator: prop => ['main', 'investors'].includes(prop)
        }
    },
    directives: {
        phone: phoneMask
    },
    data () {
        return {
            val: this.value,
            error: false,
            focus: false,
            text: {
                errors: {
                    required: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field',
                    invalid: {
                        email: this.$root.lang === 'ru' ? 'Формат для e-mail указан неверно' : 'Invalid email format'
                    }
                }
            }
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) {
                this.change('')
                this.error = false
            }
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.change('')
        })
    },
    methods: {
        change (value) {
            if (this.required && !value) {
                this.val = ''
                this.error = true
            } else {
                this.val = value
                this.error = false

                if (this.type === 'email') {
                    this.checkEmail(value)
                } else if (this.type === 'tel') {
                    this.checkTel(value)
                }
            }
            this.$emit('change', { value: this.val, name: this.name })
        },
        input (value) {
            if (this.required && !value) {
                this.val = ''
                this.error = true
            } else {
                this.val = value
                this.error = false

                if (this.type === 'email') {
                    this.checkEmail(value)
                } else if (this.type === 'tel') {
                    this.checkTel(value)
                }
            }
            this.$emit('input', { value: this.val, name: this.name })
        },
        enter (value) {
            this.$emit('enter', {value: this.val, name: this.name})
        },
        checkEmail (value) {
            if (!this.validateEmail(value)) this.error = true
        },
        checkTel (value) {
            if (value.length < 17) this.error = true
        },
        validateEmail (email) {
            /* eslint-disable */
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        },
        validate () {
            if (this.required && !this.val) {
                this.error = true
            } else {
                this.error = false

                if (this.type === 'email') {
                    this.checkEmail(this.val)
                }
            }
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
