import { render, staticRenderFns } from "./SuppliersPage.vue?vue&type=template&id=378f8afb&"
import script from "./SuppliersPage.vue?vue&type=script&lang=js&"
export * from "./SuppliersPage.vue?vue&type=script&lang=js&"
import style0 from "./SuppliersPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports