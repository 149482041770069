<template>
    <section class="ip-key-figures">
        <z-caption tag="h2" weight="300" family="fact" color="white" uppercase v-html="text.maintitle"></z-caption>
        <z-switch
            class="u-bottom-margin--m"
            id="key-figures-switch"
            v-model="switchState"
            inverted
            both-value
        >
            <template v-slot:left>
                <span v-html="text.financial"></span>
            </template>
            <template v-slot:right>
                <span v-html="text.operating"></span>
            </template>
        </z-switch>
        <div class="row">
            <div
                v-for="(item, index) in this.displayedData"
                :key="index"
                class="col-default-6 col-mobile-12"
            >
                <z-key-indicator
                    inverted
                    :value="item.value"
                    :name="item.name"
                    :trend="item.trend && item.trend"
                    :trend-text="item['trend-text'] && item['trend-text']"
                ></z-key-indicator>
            </div>
        </div>
        <div class="ip-key-figures__footer">
            <z-button
                inverted
                kind="secondary"
                tag="a"
                :href="getLink('/investors/reporting-center/results/')"
                class="js-analytics"
                :data-analytics-label="`ClickKeyFiguresResultsInvestors${capitalizeLang}`"
                data-analytics-action="click"
                data-analytics-category="content"
            >
                <span v-html="text.link"></span>
            </z-button>
            <slot name="databook"/>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import { getLink } from '@/utils/get-link'

export default {
    name: 'ip-key-figures',
    data () {
        return {
            switchState: false,
            text: {
                maintitle: localize({
                    'ru': 'Операционные показатели за&nbsp;2024',
                    'en': 'Key figures FY&nbsp;2024'
                }),
                financial: localize({
                    'ru': 'Финансовые',
                    'en': 'Financial'
                }),
                operating: localize({
                    'ru': 'Операционные',
                    'en': 'Operating'
                }),
                link: localize({
                    'ru': 'Отчеты и результаты',
                    'en': 'Reporting Center'
                })
            },
            figures: {
                financial: [
                    {
                        name: localize({
                            'ru': 'Выручка',
                            'en': 'Revenue'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>168</span> млрд руб.",
                            'en': "rub <span class='header-1 crisp-white-text'>168</span>bn"
                        }),
                        trend: 'up',
                        'trend-text': '+12%'
                    },
                    {
                        name: 'EBITDA',
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>18,7</span> млрд руб.",
                            'en': "rub <span class='header-1 crisp-white-text'>18.7</span>bn"
                        })
                    },
                    {
                        name: localize({
                            'ru': 'Чистый долг',
                            'en': 'Net Debt'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>35,5</span> млрд руб.",
                            'en': "rub <span class='header-1 crisp-white-text'>35.5</span>bn"
                        })
                    },
                    {
                        name: localize({
                            'ru': 'Чистый долг / LTM EBITDA',
                            'en': 'Net Debt / LTM EBITDA'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>1,9</span>x",
                            'en': "<span class='header-1 crisp-white-text'>1.9</span>x"
                        })
                    }
                ],
                operating: [
                    {
                        name: localize({
                            'ru': 'Винлаб <z-tooltip content="на 31.12.2024" theme="inverted"></z-tooltip>',
                            'en': 'Winelab <z-tooltip content="as at 31.12.2024" theme="inverted"></z-tooltip>'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>2 041</span> магазин",
                            'en': "<span class='header-1 crisp-white-text'>2,041</span> stores"
                        }),
                        // trend: 'up',
                        // 'trend-text': '+35%'
                    },
                    {
                        name: localize({
                            'ru': 'Общие отгрузки, декалитры',
                            'en': 'Total shipment, 9L cases'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>16,2 млн</span>",
                            'en': "<span class='header-1 crisp-white-text'>18 mn</span>"
                        }),
                        // trend: 'up',
                        // 'trend-text': '+7%'
                    },
                    {
                        name: localize({
                            'ru': 'Отгрузки импортных брендов, декалитры',
                            'en': 'Import brands shipment, 9L cases'
                        }),
                        value: localize({
                            'ru': "<span class='header-1 crisp-white-text'>3,4 млн</span>",
                            'en': "<span class='header-1 crisp-white-text'>3.8 mn</span>"
                        }),
                        // trend: 'up',
                        // 'trend-text': '+14%'
                    }
                ]
            }
        }
    },
    computed: {
        displayedData () {
            return this.switchState ? this.figures.operating : this.figures.financial
        },
        capitalizeLang: function () {
            return this.$options.filters.capitalizeString(this.$root.lang)
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.ip-key-figures {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint (tablet) {
        padding-bottom: 6em;
    }

    @include breakpoint (v-tablet) {
        padding-bottom: 4em;
    }

    &:before,
    &:after {
        @include breakpoint (tablet) {
            content: '';
            position: absolute;
            display: block;
            right: 0;
            bottom: 0;
        }
    }

    &:before {
        @include breakpoint (tablet) {
            width: auto;
            height: 2px;
            background-color: $token-colors-gold;
            left: -2em;
            right: -2em;
        }

        @include breakpoint (mobile) {
            left: -1.5em;
            right: -1.5em;
        }
    }

    &:after {
        @include breakpoint (tablet) {
            left: 0;
            height: 4em;
            width: 8em;
            border: 2px solid $token-colors-gold;
            border-top-left-radius: 4em;
            border-top-right-radius: 4em;
            border-bottom-left-radius: 0;
        }

        @include breakpoint (mobile) {
            height: 3em;
            width: 6em;
            border-top-left-radius: 3em;
            border-top-right-radius: 3em;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-top: auto !important;
        @include padding-level(top, M);

        @include breakpoint (v-tablet) {
            flex-direction: column;
            align-items: flex-end;
        }

        .z-button {
            margin-right: 100px;

            @include breakpoint (v-tablet) {
                margin-right: 0;
                margin-bottom: 18px;
            }
        }
    }

    .z-key-indicator {
        width: 100%;
    }

    .z-key-indicator__value {
        margin-right: 0;
        font-size: 26px;

        .header-1 {
            font-size: 40px;
        }
    }

    .z-key-indicator__trend {
        font-family: 'Fact';
    }

    .z-key-indicator__value {
        font-weight: 300;
    }
}
</style>
