<template>
    <div class="investor-materials">
        <div class="container">
            <div class="investor-materials__wrapper">
                <div class="row">
                    <div class="col-default-6 col-tablet-12">
                        <div class="investor-materials-case">
                            <z-caption
                                tag="h2"
                                border-decor
                                weight="400"
                                family="liberteen"
                                uppercase
                                class="text-size-4xl u-collapse--bottom"
                            >
                                <span v-html="text.title.materials"></span>
                            </z-caption>
                            <z-caption
                                tag="h3"
                                weight="400"
                                family="liberteen"
                                class="investor-materials-case__title"
                            >
                                <span v-html="text.title.case"></span>
                            </z-caption>
                            <p v-html="text.case" class="investor-materials-case__text"></p>
                            <z-button
                                tag="a"
                                :href="getLink('/investors/investment-case/')"
                                class="investor-materials-case__button"
                            >
                                <span v-html="text.button.more"></span>
                            </z-button>
                        </div>
                    </div>
                    <div class="col-default-6 col-tablet-12">
                        <div class="investor-materials-reports" v-if="reports && reports.length">
                            <z-card>
                                <template v-slot:content>
                                    <z-caption
                                        tag="h2"
                                        weight="400"
                                        family="liberteen"
                                        border-decor
                                        class="text-size-3xl"
                                    >
                                        <span v-html="text.title.reports" />
                                    </z-caption>
                                    <z-filelist class="investor-materials-reports__filelist">
                                        <z-filelist-item
                                            v-for="(item, index) in reports"
                                            :key="index"
                                            icon="file/pdf"
                                            :info="item.size ? `${item.size}, pdf`: null"
                                        >
                                            <z-link :href="item.link" target="_blank">
                                                <span v-html="item.title" />
                                            </z-link>
                                        </z-filelist-item>
                                    </z-filelist>
                                </template>
                            </z-card>
                        </div>
                        <div class="investor-materials-indicators">
                            <z-card>
                                <template v-slot:content>
                                    <z-caption
                                        tag="h2"
                                        weight="400"
                                        family="liberteen"
                                        border-decor
                                        class="text-size-3xl"
                                    >
                                        <span v-html="text.title.indicators" />
                                    </z-caption>
                                    <div class="investor-materials-indicators__list">
                                        <div class="row">
                                            <div
                                                v-for="(item, index) in indicators"
                                                :key="index"
                                                class="col-default-6 col-tablet-4 col-v-tablet-6 col-mobile-12"
                                            >
                                                <z-key-indicator
                                                    :bordered="false"
                                                    :value-before="item['value-before']"
                                                    :value="item.value"
                                                    :value-text="item['value-text']"
                                                    :name="item.name"
                                                    :trend="item.trend ? item.trend : null"
                                                    :trend-text="item['trend-text'] ? item['trend-text'] : null"
                                                ></z-key-indicator>
                                            </div>
                                            <div class="col-default-6 col-tablet-4 col-v-tablet-6 col-mobile-12 u-align-self--end">
                                                <z-button
                                                    v-html="text.button.indicator"
                                                    class="investor-materials-indicators__button"
                                                    kind="secondary"
                                                    tag="a"
                                                    :href="getLink('/investors/reporting-center/results/')"
                                                    size="s"
                                                ></z-button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </z-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getLink } from '@/utils/get-link'
import { localize } from '@/utils/i18n'

export default {
    name: 'investor-materials',
    props: {
        reports: {
            type: Array,
            default: () => []
        },
        indicators: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            text: {
                case: localize({
                    ru: 'Novabev Group входит в&nbsp;топ-10 международных алкогольных компаний, а&nbsp;ее&nbsp;флагманский бренд широко известен во&nbsp;всем мире.',
                    en: 'Novabev Group is&nbsp;among top-10 spirits companies globally, and its flagship brand is&nbsp;widely known all over the world.'
                }),
                title: {
                    materials: localize({
                        ru: 'Материалы инвесторам',
                        en: 'Materials for investors'
                    }),
                    case: localize({
                        ru: 'Инвестиционный кейс',
                        en: 'Investment case'
                    }),
                    reports: localize({
                        ru: 'Отчеты и&nbsp;результаты',
                        en: 'Reporting Center'
                    }),
                    indicators: localize({
                        ru: 'Ключевые показатели 2024',
                        en: 'Key figures 2024'
                    })
                },
                button: {
                    more: localize({
                        ru: 'Подробнее',
                        en: 'More info'
                    }),
                    indicator: localize({
                        ru: 'Отчеты и результаты',
                        en: 'Reporting Center'
                    })
                }
            }
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.investor-materials {
    $parent: &;

    margin-top: 160px;
    background-image: url('/images/investor-materials-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 88px 0;

    @include breakpoint (mobile) {
        padding: 56px 0;
    }

    .z-card {
        border: 1px solid #ff9573;
        background-color: #ecfbff;
        position: relative;

        &__main {
            padding: 32px;

            @include breakpoint (mobile) {
                padding: 24px;
            }
        }

        &__content {
            @include breakpoint (tablet) {
                padding: 0;
            }
            .z-caption {
                font-family: "Future";
                text-transform: uppercase;
            }
        }
    }

    .z-button {
        color: $token-colors-kaviar-black;
        font-family: "Liberty";
        text-transform: uppercase;

        &--kind {
            &-primary {
                background-color: #ecfbff;
                font-size: 16px;
                &:hover {
                    background-color: #fff;
                    color: #97D8EB;
                }
            }

            &-secondary {
                background-color: #ecfbff;
                border: 2px solid #00598a;
                font-size: 16px;
                color: #00598a;
                &:hover {
                    background-color: #ecfbff;
                    border-color: #97D8EB;
                    color: #97D8EB;
                }
            }
        }
    }

    .z-key-indicator {
        &__group {
            line-height: 1;
        }

        &__name {
            font-family: "Future";
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        &__value {
            font-family: "Steelfish";
            font-size: 70px;
            font-weight: 400;
            color: #ff9573;
            margin-right: 10px;
            line-height: 1;
        }

        &__value-text {
            font-family: "Steelfish";
            font-size: 50px;
            color: #ff9573;
        }

        &__trend {
            margin-top: 0;
            font-family: "Fact";
            color: #00598a;

            @include breakpoint (tablet) {
            margin-top: 8px;
        }
        }
    }

    .z-caption--border-decor:after {
        height: 1px;
        background-color: #ff9573;

        @include breakpoint (mobile) {
            display: none;
        }
    }

    .row {
        margin-top: -32px;

        > [class*=col-] {
            margin-top: 32px;
        }
    }

    #{$parent}__wrapper {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(-88px + 32px); //компенсаия margin-top: -32px у row
            display: block;
            height: calc(100% + 88px - 32px); //88px это внутренний паддинг у родителя
            width: 2px;
            background-color: #ff9573;

            @include breakpoint (tablet) {
                display: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: -1px;
            bottom: 0;
            display: block;
            max-width: 277px;
            max-height: 277px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='279' height='278' viewBox='0 0 279 278' fill='none'%3E%3Cpath d='M278.256 277C125.528 277 1.71811 153.19 1.71812 0.462207' stroke='%23ff9573' stroke-width='2'/%3E%3C/svg%3E");
            background-size: 75% auto;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: left bottom;
            z-index: -1;

            @include breakpoint (tablet) {
                display: none;
            }
        }
    }

    #{$parent}-case {
        padding: 32px 0 30px 100px;

        @include breakpoint (tablet) {
            padding: 32px;
            border-bottom: none;
        }

        @include breakpoint (mobile) {
            padding: 0;
        }

        .z-caption {
            font-family: "Liberty";
            font-size: 22px;
            color: #ff9573;
        }

        &__title {
            line-height: 1;
            font-family: "Steelfish"!important;
            font-size: 70px!important;
            font-weight: 400;
            color: $token-colors-crisp-white!important;
            margin-top: $token-spacers-m;
            margin-bottom: $token-spacers-m;

            @include breakpoint (mobile) {
                font-size: 24px;
                margin-top: 32px;
                margin-bottom: 26px;
            }
        }

        &__text {
            font-family: "Future";
            font-size: 20px;
            text-transform: uppercase;
            color: $token-colors-crisp-white;

            @include breakpoint (mobile) {
                font-size: 16px;
            }
        }

        &__button {
            margin-top: $token-spacers-m;

            @include breakpoint (mobile) {
                margin-top: $token-spacers-xs;
                width: 100%;
            }
        }
    }

    #{$parent}-reports {
        &__filelist {
            margin-top: 32px;
            .z-link {
                font-family: "Fact";
                color: #00598a;
            }
            .z-filelist-item__info {
                font-family: "Future";
                text-transform: uppercase;
            }
        }

        .z-card {
            overflow: hidden;

            &__main {
                padding-right: 115px;

                @include breakpoint (v-tablet) {
                    padding-right: 32px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 115px;
                height: 115px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='116' height='116' viewBox='0 0 116 116' fill='none'%3E%3Cpath d='M0.999996 116C1 52.4872 52.4873 1 116 1.00001' stroke='%23ff9573'/%3E%3C/svg%3E");
                background-repeat: no-repeat;

                @include breakpoint (v-tablet) {
                    display: none;
                }
            }
        }
    }

    #{$parent}-indicators {
        margin-top: 24px;

        &__list {
            margin-top: 32px;
        }

        &__button {
            @include breakpoint (mobile) {
                width: 100%;
            }
        }
    }
}
</style>
