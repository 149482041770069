<template>
    <z-list
        class="z-filelist"
        :class="buildClass"
        :styled="false"
        tag="ul"
    >
        <slot/>
    </z-list>
</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        horizontal: Boolean,
        inverted: Boolean,
        border: Boolean,
        table: Boolean
    },
    computed: {
        buildClass () {
            return [
                {
                    'z-filelist--inverted': this.inverted,
                    'z-filelist--vertical': !this.horizontal,
                    'z-filelist--border': this.border,
                    'z-filelist--horizontal': this.horizontal && !this.table,
                    'z-filelist--horizontal-table': this.horizontal && this.table,
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
