<template>
    <div class="suppliers-page">
        <section class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-9">
                    <z-caption weight="300" family="steelfish" tag="h2" size="m" class="suppliers-page__title" uppercase>Открытые закупки и продажи Novabev Group</z-caption>

                    <p class="suppliers-page__subtitle">Novabev Group заинтересована в сотрудничестве с добросовестными партнерами и поставщиками: мы открыты предложениям, которые позволят расширить базу контрагентов, представляющих услуги или продукцию высокого качества. Мы регулярно проводим тендеры, поэтому у любой успешной организации есть возможность предложить свои товары или услуги и стать нашим партнером.</p>
                </div>
            </div>
        </section>

        <section class="suppliers-page-files-section suppliers-page__section-spacer--top container">
            <z-caption weight="300" family="steelfish" tag="h2" size="m" class="suppliers-page__title" uppercase>График проведения внутренних процедур</z-caption>

            <div class="row">
                <div class="col-xs-12 col-md-7 col-lg-8">
                    <z-filelist horizontal table>
                        <z-filelist-item icon="file/pdf" date="01.04.2025" info="657 Kb, pdf">
                            <z-link href="/files/ru/suppliers/butylka.pdf" theme="black" blank>Бутылка</z-link>
                        </z-filelist-item>
                        <z-filelist-item icon="file/pdf" date="01.04.2025" info="652 Kb, pdf">
                            <z-link href="/files/ru/suppliers/kolpak.pdf" theme="black" blank>Колпак</z-link>
                        </z-filelist-item>
                        <z-filelist-item icon="file/pdf" date="01.04.2025" info="667 Kb, pdf">
                            <z-link href="/files/ru/suppliers/upakovka.pdf" theme="black" blank>Упаковка</z-link>
                        </z-filelist-item>
                        <z-filelist-item icon="file/pdf" date="01.04.2025" info="648 Kb, pdf">
                            <z-link href="/files/ru/suppliers/etiketka.pdf" theme="black" blank>Этикетка</z-link>
                        </z-filelist-item>
                    </z-filelist>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <div class="suppliers-page__contact-card">
                        <span class="suppliers-page__contact-card-title">Руководитель тендерного отдела <br>Манохина Алена Викторовна</span>

                        <a href="mailto:E.Manokhina@zao-rps.ru" class="suppliers-page__email">E.Manokhina@zao-rps.ru</a>

                        <span>
                            <a href="tel:74957442901" class="suppliers-page__contact-card-phone">+7(495)744-29-01</a>
                            <span class="suppliers-page__contact-card-phone--asside">(доб.13-67)</span>
                        </span>
                    </div>
                </div>
            </div>
        </section>

        <section class="suppliers-page__section-spacer--top container">
            <z-caption weight="300" family="steelfish" tag="h2" size="m" class="suppliers-page__title" uppercase>Процедуры размещенные на ЭТП</z-caption>

            <procedures />
        </section>

        <div class="suppliers-page-contacts-section__wrapper suppliers-page__section-spacer--top">
            <section class="suppliers-page-contacts-section container">
                <div class="row">
                    <div class="col-xs-12">
                        <z-caption weight="300" color="white" family="steelfish" tag="h2" size="m" class="suppliers-page__title" uppercase>Контакты</z-caption>
                    </div>

                    <div class="col-xs-12 col-sm-9 col-md-8">
                        <p class="suppliers-page__subtitle suppliers-page__subtitle--light">Если у вас возникнут вопросы или потребуется дополнительная информация, пожалуйста, свяжитесь с нами по указанным контактам, мы готовы обсудить возможности сотрудничества.</p>
                    </div>

                    <div class="col-md-4 col-md-offset-1 col-md-3">
                        <div class="suppliers-page-contacts-section__phone-wrapper">
                            <a href="tel:74957442901" class="suppliers-page-contacts-section__phone">+7 (495) 744-29-01</a>

                            <span class="suppliers-page-contacts-section__phone--asside">доб. 12-53</span>
                        </div>
                    </div>
                </div>

                <div class="suppliers-page-contacts-section__info-item-wrapper row">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">Комплектующие для производства алкогольной продукции</span>

                            <a href="mailto:ap-km@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">ap-km@zao-rps.ru</a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-md-offset-1">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">Руководитель департамента закупок Эфендиев Эмин Гаджимурадович</span>

                            <a href="mailto:EfendievEG@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">EfendievEG@zao-rps.ru</a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-md-offset-1">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">Несырьевые закупки</span>

                            <a href="mailto:nkz@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">nkz@zao-rps.ru</a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">Ингредиенты пищевые</span>

                            <a href="mailto:in@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">in@zao-rps.ru</a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-md-offset-1">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">Упаковочные материалы</span>

                            <a href="mailto:D.Cherkasov@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">D.Cherkasov@zao-rps.ru</a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-md-offset-1">
                        <div class="suppliers-page-contacts-section__info-item">
                            <span class="suppliers-page-contacts-section__info-item-text">POSM-материалы</span>

                            <a href="mailto:posm@zao-rps.ru" class="suppliers-page__email suppliers-page__email--light">posm@zao-rps.ru</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section class="container suppliers-page-blockquote-section suppliers-page__section-spacer--top suppliers-page__section-spacer--bottom">
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-md-5">
                    <img class="suppliers-page-blockquote-section__image" src="/images/suppliers/director.webp" alt="Руководитель департамента закупок">
                </div>

                <div class="col-xs-12 col-sm-8 col-md-offset-1 col-md-6 suppliers-page-blockquote-section__blockquote-wrapper">
                    <z-blockquote
                        author="Эфендиев Эмин Гаджимурадович"
                        post="Руководитель департамента закупок"
                        detail="Мы стремимся к оптимизации процессов и эффективному управлению ресурсами, обеспечивая нашим клиентам высокое качество товаров и услуг по доступным ценам. Отношения с поставщиками выстраиваем на основе честности, справедливости и прозрачности в коммуникациях, что способствует развитию бизнеса обеих сторон. Присоединяйтесь, будем вместе создавать лучшее в алкогольной индустрии."
                    ></z-blockquote>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Procedures from './components/Procedures/Procedures.vue'

export default {
    name: 'suppliers-page',
    props: {},
    components: { Procedures },
    data () {
        return {
        }
    }
}
</script>

<style lang="scss">
body {
    background: #ecfbff;
    font-family: "Fact";
}

header {
    position: relative;
}

main {
    overflow: visible;
}

.breadcrumb + h1 {
    font-size: 40px;
    font-family: "Liberty";
    margin-bottom: 112px;
}

.suppliers-page {
    --color-kaviar-black: #1D1D1B;
    --color-crisp-white: #{$token-colors-crisp-white};
    --color-gold: #{$token-colors-gold};
    --color-blue-dark: #{$token-colors-blue-dark};
    --color-blue-darkest: #114167;
    --color-deep-blue: #{$token-colors-blue-darkest};
    --color-ice-blue: #A5D7E8;

    --spacer-4xl: 64px;
    --spacer-m: 30px;

    @include breakpoint-min($md) {
        --spacer-4xl: 112px;
        --spacer-m: 40px;
    }
}

.suppliers-page {
    &__title {
        font-size: 38px;
        margin-bottom: var(--spacer-m) !important;
    }

    &__subtitle {
        font-family: "Future";
        font-size: 24px;
        line-height: 150%;

        &--light {
            color: var(--color-crisp-white);
        }
    }

    &__email {
        color: var(--color-blue-darkest);
        font-size: 20px;
        font-weight: 400;
        line-height: 100%;

        &--light {
            color: var(--color-ice-blue);
        }
    }

    &__section-spacer {
        &--top {
            margin-top: var(--spacer-4xl);
        }

        &--bottom {
            margin-bottom: var(--spacer-4xl);
        }
    }

    &__contact-card {
        border: 1px solid var(--color-gold);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-title {
            color: var(--color-blue-dark);
            font-family: "Future";
            font-size: 18px;
            font-weight: 300;
            line-height: 120%;
        }

        &-phone {
            color: var(--color-blue-darkest);
            font-size: 20px;
            font-weight: 400;
            line-height: 100%;

            &--asside {
                color: var(--color-blue-dark);
                font-family: "Future";
                font-size: 18px;
                font-weight: 300;
                line-height: 100%;
            }
        }
    }
}

.suppliers-page {
    $parent: &;
    $contactsSectionParent: & + '-contacts-section';

    &-files-section {
        .row {
            gap: 32px;

            @include breakpoint-min($md) {
                gap: 0;
            }
        }
    }

    &-contacts-section__wrapper {
        padding-top: var(--spacer-4xl);
        padding-bottom: var(--spacer-4xl);
        background-color: var(--color-deep-blue);

        #{$contactsSectionParent} {
            &__phone-wrapper {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                height: 100%;

                @include breakpoint-min($md) {
                    align-items: center;
                }

                #{$contactsSectionParent}__phone {
                    color: var(--color-crisp-white);
                    text-align: center;
                    font-family: "Liberty";
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 120%;
                    margin-top: 20px;

                    @include breakpoint-min($md) {
                        margin-top: 0px;
                    }

                    &--asside {
                        color: var(--color-ice-blue);
                        text-align: center;
                        font-family: "Future";
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 150%;
                    }
                }
            }

            &__info-item-wrapper {
                margin-top: 56px;
                margin-bottom: 40px;
                gap: 40px 0px;

                #{$contactsSectionParent}__info-item {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    &-text {
                        color: var(--color-crisp-white);
                        font-family: "Future";
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 127%;
                    }
                }
            }
        }
    }

    &-blockquote-section {
        &__image {
            aspect-ratio: 460 / 480;
            width: 100%;
        }

        &__blockquote-wrapper {
            display: flex;
            margin-top: 16px;

            @include breakpoint-min($md) {
                margin-top: 0;
                align-items: center;
            }
        }
    }
}

</style>
