<template>
    <section class="ip-why-invest">
        <div class="container">
            <div class="row">
                <div class="col-default-8 col-tablet-12">
                    <z-caption class="ip-why-invest__title" tag="h2" border-decor weight="300" family="fact" uppercase v-html="text.title['why-invest']"></z-caption>
                </div>
                <div class="col-default-4 hide-me--tablet">
                    <z-button
                        class="ip-why-invest__btn u-push-right"
                        tag="a"
                        :href="getLink('/investors/investment-case/')"
                    >
                        <span v-html="text.link"></span>
                    </z-button>
                </div>
            </div>
            <div class="row">
                <div class="col-default-4 col-tablet-5 col-mobile-12">
                    <div class="reason-item reason-item--first">
                        <div class="reason-item__prefix">
                            <span class="reason-item__prefix-text">01</span>
                        </div>
                        <div class="reason-item__body">
                            <a :href="text.anchors.keyPlayer" class="reason-item__title extra-large-text extra-large-text--black" v-html="text.glance.title.first"></a>
                        </div>
                    </div>
                </div>
                <div class="col-default-8 col-tablet-7 col-mobile-12">
                    <div class="ip-why-invest__list-wrap">
                        <div class="ip-why-invest__uno">№1</div>
                        <z-list>
                            <z-list-item>
                                <span v-html="text.leader.list.first"></span>
                            </z-list-item>
                            <z-list-item>
                                <span v-html="text.leader.list.second"></span>
                            </z-list-item>
                            <z-list-item>
                                <span v-html="text.leader.list.third"></span>
                            </z-list-item>
                            <z-list-item>
                                <span v-html="text.leader.list.fourth"></span>
                            </z-list-item>
                        </z-list>
                    </div>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <div class="reason-item reason-item--second">
                        <div class="reason-item__prefix">
                            <div class="reason-item__prefix-text">02</div>
                        </div>
                        <div class="reason-item__body">
                            <a :href="text.anchors.stableMarket" class="reason-item__title" v-html="text.glance.title.second"></a>
                        </div>
                    </div>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <div class="reason-item reason-item--third">
                        <div class="reason-item__prefix">
                            <div class="reason-item__prefix-text">03</div>
                        </div>
                        <div class="reason-item__body">
                            <a :href="text.anchors.strategy" class="reason-item__title" v-html="text.glance.title.third"></a>
                        </div>
                    </div>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <div class="reason-item reason-item--forth">
                        <div class="reason-item__prefix">
                            <div class="reason-item__prefix-text">04</div>
                        </div>
                        <div class="reason-item__body">
                            <a :href="text.anchors.efficiency" class="reason-item__title" v-html="text.glance.title.fourth"></a>
                        </div>
                    </div>
                </div>
                <div class="col-default-12 col-tablet-6 col-mobile-12 hide-me--default show-me--tablet">
                    <div class="reason-item reason-item--fifth">
                        <div class="ip-why-invest__btn-wrapper">
                            <z-button
                                class="ip-why-invest__btn"
                                tag="a"
                                :href="getLink('/investors/investment-case/')"
                            >
                                <span v-html="text.link"></span>
                            </z-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import { getLink } from '@/utils/get-link'

export default {
    name: 'ip-why-invest',
    data () {
        return {
            text: {
                title: {
                    'why-invest': localize({
                        'ru': 'Инвестиционный кейс',
                        'en': 'At a glance: Novabev Group'
                    })
                },
                glance: {
                    title: {
                        first: localize({
                            'ru': 'Лидер<br>индустрии',
                            'en': 'Industry<br>leader'
                        }),
                        second: localize({
                            'ru': 'Рыночный<br>потенциал',
                            'en': 'Market <br>potential'
                        }),
                        third: localize({
                            'ru': 'Амбициозная<br>стратегия роста',
                            'en': 'Growth<br>ambitions'
                        }),
                        fourth: localize({
                            'ru': 'Концентрация<br>на&nbsp;эффективности',
                            'en': 'Focus on<br>efficiency'
                        })
                    }
                },
                leader: {
                    list: {
                        first: localize({
                            'ru': 'Производитель водки и&nbsp;ЛВИ в&nbsp;России',
                            'en': 'Distilled and flavored spirits producer in&nbsp;Russia'
                        }),
                        second: localize({
                            'ru': 'Производитель виски в&nbsp;России',
                            'en': 'Whiskey producer in&nbsp;Russia'
                        }),
                        third: localize({
                            'ru': 'Независимый импортер спиртных напитков и&nbsp;вина',
                            'en': 'Independent importer of&nbsp;alcoholic beverages and wine'
                        }),
                        fourth: localize({
                            'ru': 'В&nbsp;супер-премиальном сегменте в&nbsp;России',
                            'en': 'In&nbsp;the super-premium vodka segment in&nbsp;Russia'
                        })
                    }
                },
                link: localize({
                    'ru': 'Инвестиционный кейс',
                    'en': 'Investment case'
                }),
                anchors: {
                    keyPlayer: localize({
                        ru: '/investors/investment-case/#key-player',
                        en: '/en/investors/investment-case/#key-player'
                    }),
                    stableMarket: localize({
                        ru: '/investors/investment-case/#stable-market',
                        en: '/en/investors/investment-case/#stable-market'
                    }),
                    strategy: localize({
                        ru: '/investors/investment-case/#growth-strategy',
                        en: '/en/investors/investment-case/#growth-strategy'
                    }),
                    efficiency: localize({
                        ru: '/investors/investment-case/#efficiency',
                        en: '/en/investors/investment-case/#efficiency'
                    })
                }
            }
        }
    },
    methods: {
        getLink: getLink
    }
}
</script>

<style lang="scss">
.ip-why-invest {
    $why-invest: &;
    background: $token-colors-crisp-white;
    position: relative;

    .z-list--size-m > .z-list-item {
        font-weight: 300;
    }

    .container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 51px;
            width: 550px;
            right: (2.5/12*100%);
            display: block;
            border: 2px solid transparent;
            border-right-color: $token-colors-gold;
            border-top-color: $token-colors-gold;
            border-left: 2px;
            border-bottom: 2px;
            height: 371px;
            border-top-right-radius: 370px;

            @include breakpoint (tablet) {
                content: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -62px;
            right: (1.15/12*100%);
            display: block;
            border: 2px solid $token-colors-gold;
            border-bottom-color: transparent;
            width: 16em;
            height: 8em;
            border-top-left-radius: 8em;
            border-top-right-radius: 8em;

            @include breakpoint (laptop) {
                width: 9em;
                height: 4.5em;
                right: (1.75/12*100%);
                border-top-left-radius: 4.5em;
                border-top-right-radius: 4.5em;
            }

            @include breakpoint (tablet) {
                content: none;
            }
        }
    }

    &__title {
        background-color: white;
        padding-right: 30px;
        width: fit-content;

        @include breakpoint (tablet) {
            width: auto;
            padding-right: 0;
            background-color: transparent;
        }
    }

    &__uno {
        font-size: 32px;
        line-height: 40px;
        font-family: 'Fact';
        margin-bottom: 20px;
    }

    &__list-wrap {
        margin-bottom: $token-spacers-l;
        position: relative;

        @include breakpoint (tablet) {
            &:before {
                content: '';
                position: absolute;
                bottom: -60px;
                right: -32px;
                display: block;
                border: 2px solid transparent;
                border-top-color: $token-colors-gold;
                border-left-color: $token-colors-gold;
                width: 6em;
                height: 6em;
                border-top-left-radius: 6em;
            }
        }

        @include breakpoint (mobile) {
            margin-bottom: $token-spacers-s;

            &:before {
                bottom: -4em;
                right: -24px;
                width: 4em;
                height: 8em;
                margin-bottom: -44px;
                border-top-left-radius: 4em;
                border-bottom-left-radius: 4em;
            }
        }
    }

}
.reason-item {
    $reason-item: &;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 18px;
    padding-bottom: 32px;
    padding-left: 25px;
    padding-right: 20px;
    min-height: 240px;

    @include breakpoint (tablet) {
        padding-right: 0;
        padding-top: 24px;
        padding-bottom: 0;
        min-height: unset;
    }

    @include breakpoint (v-tablet) {
        &--second,
        &--forth {
            padding-left: 0;
        }
    }

    @include breakpoint (mobile) {
        min-height: unset;
        padding: 0;
        padding-top: 24px;
    }

    &--left-decor:after {
        content: '';
        position: absolute;
        top: -2em;
        bottom: 0;
        left: -$var-grids-gutter/2;
        width: 2px;
        background-color: $token-colors-gold;

        @include breakpoint (v-tablet) {
            top: -1.5em;
        }
    }

    &--second,
    &--third,
    &--forth {
        &:before {
            content: '';
            position: absolute;
            top: -2em;
            left: -$var-grids-gutter/2;
            right: -$var-grids-gutter/2;
            height: 2px;
            background-color: $token-colors-gold;

            @include breakpoint (v-tablet) {
                top: -1.5em;
                left: -$var-grids-gutter/2 - 32px;
                right: -$var-grids-gutter/2 - 32px;
            }

            @include breakpoint (mobile) {
                top: 1px;
                left: -$var-grids-gutter/2 - 32px;
                right: -$var-grids-gutter/2 - 32px;
            }
        }
    }

    &--first {
        padding-top: 0;

        @include breakpoint (v-tablet) {
            min-height: unset;
            padding-bottom: 0;
            padding-left: 0;
        }
    }

    &--third {
        @extend .reason-item--left-decor;

        @include breakpoint (mobile) {
            &:before {
                left: 0;
                right: 62px;
            }
        }
    }

    &--forth {
        &:before {
            @include breakpoint (v-tablet) {
                content: none;
            }
            @include breakpoint (mobile) {
                content: '';
            }
        }
    }

    &--fifth {
        display: flex;
        height: 100%;
        align-items: flex-end;
        @extend .reason-item--left-decor;

        .ip-why-invest__btn-wrapper {
            margin-left: auto;
        }
        @include breakpoint (v-table) {
            content: none;
        }

        @include breakpoint (mobile) {
            padding-top: 0;

            &:after {
                content: none;
            }
            .ip-why-invest__btn-wrapper {
                margin-left: unset;
            }
        }
    }

    &--second,
    &--third {
        &:after {
            @include breakpoint (mobile) {
                content: '';
                position: absolute;
                top: 0;
                bottom: -25px;
                left: unset;
                right: 34px;
                width: 2px;
                background-color: $token-colors-gold;
            }
        }
    }

    &__prefix {
        height: 24px;
        width: auto;
        margin-right: 18px;
        display: flex;
        align-items: flex-start;
    }

    &__prefix-text {
        font-size: 20px;
        line-height: 1.2;
    }
    &__body {
        flex: 1 1 auto;
        z-index: 1;
    }

    &__title {
        font-family: 'Liberty';
        text-decoration: none;
        color: #1c1c1c;
        font-size: 30px;
        line-height: 1.2;

        @include breakpoint (v-tablet) {
            font-size: 20px;
        }
    }
}
</style>
